import axios from "@axios";

const getUsers = (options = {}) => {
  return axios.get("/users", options);
};

const addUsers = (data) => {
  return axios.post("/users", data);
};

const updateUsersById = (userId, data) => {
  return axios.put(`/users/${userId}`, data);
};

const deleteUsersById = (userId) => {
  return axios.delete(`/users/${userId}`);
};

export default {
  getUsers,
  addUsers,
  updateUsersById,
  deleteUsersById,
};

<template>
  <VChip v-bind="customizar" small v-if="status">
    <v-icon small class="mr-2" v-if="customizar.icon">
      {{ customizar.icon }}
    </v-icon>
    {{ $t(status) }}
  </VChip>
</template>

<script>
import { ref, computed } from "@vue/composition-api";
import { mdiCheckCircleOutline } from "@mdi/js";

export default {
  name: "TaskStatus",
  props: {
    status: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { status } = props;
    const customizar = computed(() => {
      switch (status) {
        case "pending_approval":
          return {
            outlined: true,
            color: "warning",
          };

        case "approval_rejected":
          return {
            elevated: true,
            color: "error",
          };

        case "pending_validation":
          return {
            outlined: true,
            color: "primary",
          };

        case "validation_rejected":
          return {
            elevated: true,
            color: "error",
          };

        case "completed":
          return {
            elevated: true,
            color: "success",
            icon: mdiCheckCircleOutline,
          };

        default:
          return {
            outlined: true,
          };
      }
    });

    return {
      customizar,
    };
  },
};
</script>

<style></style>

<template>
  <wrapper-form v-model="isFormValid" :model="form" ref="form" v-if="getAreaManagerSelect">
    <v-row>
      <v-col cols="12" col>
        <label for="">Area</label>
        <p class="red--text">{{ getAreaManagerSelect.name }}</p>
      </v-col>
      <v-col cols="12" col>
        <FormTextField
          :label="$t('Title')"
          :rules="[validators.required]"
          v-model="form.title"
        />
      </v-col>
      <v-col cols="12" col>
        <FormTextArea
          :label="$t('Task description')"
          :rules="[validators.required]"
          :rows="15"
          v-model="form.description"
        />
      </v-col>
      
      <v-col cols="12" col>
        <v-file-input
          v-model="form.files"
          multiple
          show-size
          counter
        />
      </v-col>

      <v-col cols="12" col>
        <h3>{{ $t("approvers") }}</h3>
        <small>{{ $t("MESSAGE_ADD_USER_TO_TASKS") }}</small>

        <UserSelect multiple :label="$t('approvers')" role="approver" v-model="form.approver_users" />
      </v-col>
      <v-col cols="12" col>
        <h3>{{ $t("validators") }}</h3>
        <small>{{ $t("MESSAGE_ADD_USER_TO_TASKS") }}</small>

        <UserSelect multiple :label="$t('validators')" role="validator" v-model="form.validator_users" />
      </v-col>
      <v-col cols="12" col></v-col>

      <v-col cols="12" col>
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn dark v-bind="attrs" v-on="on" v-if="isAdd">
              {{ $t("Add task") }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in saveOptions"
              :key="index"
              :disabled="!isFormValid"
            >
              <v-list-item-title @click="onFormAdd(item)">{{
                item.label
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          color="primary"
          type="submit"
          :disabled="!isFormValid"
          @click.prevent="onSubmitForm"
          v-if="isEdit"
        >
          {{ $t("Save") }}
        </v-btn>
      </v-col>
    </v-row>
    <Loading v-model="loading" />
  </wrapper-form>
</template>

<script>
import {
  reactive,
  ref,
  getCurrentInstance,
  computed,
} from "@vue/composition-api";

import FormTextArea from "@/components/forms/TextArea";
import FormTextField from "@/components/forms/TextField";
import WrapperForm from "@/components/general/WrapperForm";
import AreaSelect from "@/components/areas/AreaSelect";
import UserSelect from "@/components/users/UserSelect";
import Loading from "@/components/general/Loading";
import { required, isSelected } from "@core/utils/validation";
import api from "@/services";
import store from "@/store";

const defaultForm = {
  area_id: null,
  project_id: null,
  title: null,
  description: null,
  validator_users: [],
  approver_users: [],
  files: []
};

export default {
  name: "TaskForm",
  components: {
    FormTextArea,
    WrapperForm,
    AreaSelect,
    FormTextField,
    UserSelect,
    Loading
},
  props: {
    isAdd: Boolean,
    isEdit: Boolean,
    model: Object,
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy;
    const { isAdd, isEdit, model = null } = props;
    const isFormValid = ref(false);
    const loading = ref(false);

    const form = reactive(
      model || defaultForm 
    );

    const saveOptions = computed(() => [
      { key: "only-create", label: vm.$t("Only create") },
      { key: "create-and-template", label: vm.$t("Create and template task") },
    ]);

    const getProjectSelect = computed(
      () => store.getters["projects/getProjectSelect"]
    );

    const getAreaManagerSelect = computed(
      () => store.getters["areas/getAreaManagerSelect"]
    );

    const toggleLoading = () => {
      loading.value = !loading.value;
    }

    const onFormAdd = async ({ key: keySaveOption }) => {
      try {
        const project_id = getProjectSelect.value?.id;
        const area_id = getAreaManagerSelect.value?.id;
        const is_save_template =
          keySaveOption === "create-and-template" ? true : false;

        const { title, description, validator_users = [], approver_users = [], files = [] } = form;

        const formData = new FormData();

        formData.append("project_id", project_id);
        formData.append("area_id", area_id);
        formData.append("is_save_template", is_save_template);
        formData.append("title", title);
        formData.append("description", description);
        validator_users.forEach(i => formData.append("validator_users[]", i))
        approver_users.forEach(i => formData.append("approver_users[]", i))
        files.forEach(i => formData.append("files[]", i))

        toggleLoading();

        const { data: response } = await api.addProjectTasks(project_id, formData);
        const { data, success } = response;

        if (success) {
          store.dispatch("areas/onSetTaskAreaManagerSelect", data);
          store.dispatch("projects/onSetSelectProjectTasks", data);
          vm.$refs.form.$children[0].reset();
        }

        vm.$alert(vm.$t("MessageSuccess"), null, "success");
      } catch (error) {
        vm.$alert(vm.$t("MessageError"), null, "error");
      } finally {
        toggleLoading();
      }
    };

    const onFormUpdate = async () => {
      try {
        const { id: templateId } = model;
        const { data: response } = await api.updateTemplatesById(
          templateId,
          model
        );
        const { success, data: template } = response;

        if (success) emit("on-template-edit", { template });

        vm.$alert(vm.$t("MessageSuccess"), null, "success");
      } catch (error) {
        vm.$alert(vm.$t("MessageError"), null, "error");
      }
    };

    return {
      getAreaManagerSelect,
      saveOptions,
      form,
      isFormValid,
      loading,

      validators: {
        required,
        isSelected,
      },

      onFormAdd,
      onFormUpdate,
    };
  },
};
</script>

<style></style>

<template>
  <FormSelect
    :value="value"
    :placeholder="$t('Select project')"
    :label="$t('Select project')"
    :itemText="(item) => item.project_name"
    itemValue="id"
    :items="formattingProjects"
    v-bind="$attrs"
    @input="onSelectInput"
  />
</template>

<script>
import { ref, onMounted, computed } from "@vue/composition-api";
import FormSelect from "@/components/forms/Select";
import api from "@/services";
import store from "@/store";
import { getI18nLocale } from "@/plugins/i18n";

export default {
  name: "SelectArea",
  components: {
    FormSelect,
  },
  props: {
    value: {
      default: null,
    },
    projects: {
      default: () => [],
      type: Array
    },
  },
  setup(props, { emit }) {
    const { projects: projectsProp } = props;

    const projects = ref(projectsProp);
    const localei18n = computed(() => getI18nLocale());

    const formattingProjects = computed(() =>
      projects.value.map((p) => ({
        ...p,
        project_name: p[localei18n.value]?.name,
      }))
    );

    const getProjects = async () => {
      const { data: response } = await api.getProjects();
      const { data } = response;
      projects.value = data;
    };

    const onSelectInput = (e) => {
      store.commit("projects/SET_PROJECT_SELECT", e);
      emit("input", e);
    };

    onMounted(() => {
      if(!projectsProp.length) getProjects();
    });
    return {
      formattingProjects,

      onSelectInput,
    };
  },
};
</script>

<style></style>

import { ref, computed, getCurrentInstance, watch } from "@vue/composition-api";
import { getI18nLocale } from '@/plugins/i18n'
import api from "@/services";

export const useAnnouncement = ({ announcement: announcementLocal = null, announcenment = null }) => {

    const loading = ref(false);
    const announcement = ref(announcementLocal || announcenment);
    const localei18n = computed(() => getI18nLocale());
    const announcementLang = computed(() => announcement.value[localei18n.value])

    const isActive = computed(() => announcement.value?.status);
    const statusRead = computed(() => isActive ? 'Activa' : 'Finalizada');


    const announcementProjects = computed(() => announcement.value?.projects || []);

    const onToggleLoading = () => {
        loading.value = !loading.value;
    };
    
    const getAnnouncementById = async (announcementId, options = {}) => {
        onToggleLoading();
        try {
            const { data: response } = await api.getAnnouncementById(
                announcementId,
                options
            );
            
            const { data } = response;
            announcement.value = data;

        } catch (error) {}
        finally {
            onToggleLoading();
        }
    };

    return {
        announcement,
        isActive,
        statusRead,
        announcementLang,
        announcementProjects,

        loading,

        getAnnouncementById,
    }
}
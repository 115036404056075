<template>
  <v-navigation-drawer
    class="wrapper__navigation-drawer py-6"
    @input="onInputDrawer"
    :value="value"
    width="600px"
    app
    bottom
    temporary
    right
    clipped
  >
    <div class="wrapper__drawer-main py-6 px-6">
      <p class="drawer-title text-uppercase text-center mb-10" v-if="title">
        {{ title }}
      </p>

      <!-- default slot -->
      <slot></slot>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { ref, watch } from "@vue/composition-api";

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const onInputDrawer = (value) => {
      emit("input", value);
    };

    return {
      onInputDrawer,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper__navigation-drawer {
  .wrapper__drawer-main {
    .drawer-title {
      font-size: 13px !important;
      font-weight: 700;
    }
  }
}
</style>

<template>
  <FormSelect
    :value="value"
    :placeholder="$t('Select area')"
    :label="$t('Select area')"
    itemText="name"
    itemValue="id"
    :items="areas"
    v-bind="$attrs"
    @input="(e) => $emit('input', e)"
  />
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";
import FormSelect from "@/components/forms/Select";
import api from "@/services";

export default {
  name: "SelectArea",
  components: {
    FormSelect,
  },
  props: {
    value: {
      default: null,
    },
  },
  setup(props) {
    const areas = ref([]);

    const getAreas = async () => {
      const { data: response } = await api.getAreas();
      const { data } = response;
      areas.value = data;
    };

    onMounted(() => {
      getAreas();
    });
    return {
      areas,
    };
  },
};
</script>

<style></style>

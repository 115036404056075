var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getProjectSelect)?_c('wrapper-table',{attrs:{"title":_vm.$t('Areas list')},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.getProjectSelect.project_name))])]},proxy:true}],null,false,171782811)},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.mergeAreasTasks,"items-per-page":5,"loading":_vm.loadingAreas,"show-expand":""},on:{"click:row":_vm.onClickItemArea},scopedSlots:_vm._u([{key:"item.areas",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('icon-avatar',{attrs:{"text":item.name,"color":'primary'}}),_c('div',{staticClass:"d-flex ms-3"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate mr-4"},[_vm._v(_vm._s(item.name))]),_c('icon-alert',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"mr-2"}),_c('icon-message',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"mr-2"}),_c('icon-account-alert',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"mr-2"})],1)],1)]}},{key:"item.tasks",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getAreaTasks(item)))])]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('task-progress',{attrs:{"tasks":item.tasks}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [_c('td',{staticClass:"text-uppercase font-weight-semibold",attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"py-4"},[_c('v-col',{attrs:{"cols":"12","xs":"3","sm":"3"}}),_c('v-col',{attrs:{"cols":"12","xs":"8","sm":"8"}})],1)],1)]}}],null,true)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-text-field
    :value="value"
    outlined
    dense
    hide-details="auto"
    @input="onChangeInput"
    v-bind="$attrs"
    v-on="$listeners"
  ></v-text-field>
</template>

<script>
import { ref, watch } from "@vue/composition-api";

export default {
  props: {
    value: {
      default: null,
    },
  },
  setup({ value }, { emit }) {
    const getValue = ref(value);

    const onChangeInput = (value) => {
      emit("onChangeInput", getValue.value);
      emit("input", value);
    };
    return {
      getValue,
      onChangeInput,
    };
  },
};
</script>

<style></style>

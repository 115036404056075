<template>
  <v-row>
    <v-col cols="12" xs="12" sm="12" md="6">
      <areas-manager-list />
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="6">
      <areas-task-list />
    </v-col>
  </v-row>
</template>

<script>
import AreasManagerList from "@/components/areas/AreasManagerList";
import AreasTaskList from "@/components/areas/AreasTaskList";

export default {
  name: "AreasManager",
  components: {
    AreasManagerList,
    AreasTaskList,
  },

  setup(props) {
    return {};
  },
};
</script>

<style></style>

<template>
  <a href="" v-on="$listeners" class="primary--text">
    <v-icon size="16px" color="primary">{{ icon }}</v-icon>
    <span class="px-2">{{ title }}</span>
  </a>
</template>

<script>
export default {
  name: "ALink",
  props: {
    title: String,
    icon: String,
  },

  setup(props) {},
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
</style>

<template>
  <v-icon :color="color" :size="size" v-bind="$attrs">{{
    mdiAccountAlertOutline
  }}</v-icon>
</template>

<script>
import { mdiAccountAlertOutline } from "@mdi/js";

export default {
  name: "IconAccountAlert",
  props: {
    size: {
      default: "20px",
    },
    color: {
      default: "warning",
    },
  },
  setup(props) {
    return {
      mdiAccountAlertOutline,
    };
  },
};
</script>

<style></style>

<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-avatar :size="size" :color="color" v-bind="attrs" v-on="on">
        <img v-if="image" data-avatar="image" alt="Avatar" :src="image" />
        <span v-else data-avatar="text" class="white--text text-uppercase">{{
          firstLetterText
        }}</span>
      </v-avatar>
    </template>
    <span v-if="text">{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "IconAvatar",
  props: {
    image: {
      default: null,
    },
    text: {
      default: null,
    },
    size: {
      default: "30px",
    },
    color: {
      default: null,
    },
  },
  computed: {
    firstLetterText() {
      return this.text ? this.text.substr(0, 2) : "NU";
    },
  },
};
</script>

<style lang="scss" scoped>
.v-avatar {
  span {
    font-weight: 500;
  }
}
</style>

import axios from '@axios';

const login = data => {
  return axios.post('/users/login', data)
};

const register = data => {
  return axios.post('/users/register', data)
};

const forgotPassword = data => {
  return axios.post('/users/forgot-password', data)
};

const changePassword = data => {
  return axios.post('/users/change-password', data)
};

export default {
  login,
  register,
  forgotPassword,
  changePassword
};
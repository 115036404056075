<template>
   <div class="wrapper__title-section">
      <v-row class="">
        <v-col cols="12" xs="12" lg="8">
          <p :class="titleStyles" class="mb-0 pbs-0">{{ title }}</p>
        </v-col>
        <v-col cols="12" xs="12" lg="4">
          <!-- default slot -->
          <slot></slot>
        </v-col>
      </v-row>
      <v-divider></v-divider>
   </div>
</template>

<script>
import { computed } from '@vue/composition-api'

export default {
  props: {
    title: {
      default: null,
      type: String
    },
    uppercase: {
      default: false,
      type: Boolean
    },
    big: {
      default: false,
      type: Boolean
    },
    medium: {
      default: false,
      type: Boolean
    }
  },

  setup({ uppercase, big, medium }) {
    const titleStyles = computed(() => {
      return {
        'text-uppercase': uppercase,
        big,
        medium,
      }
    })
    return {
      titleStyles
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper__title-section {
  margin: 24px 0;
  p {
    font-weight: 700;

    &.big {
      font-size: 17px !important;
    }

    &.medium {
      font-size: 14px !important;
    }
  }
}
</style>
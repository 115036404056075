import axios from "@axios";

const getProjects = (options = {}) => {
  return axios.get(`/projects/`, options);
};

const getProjectById = (projectId) => {
  return axios.get(`/projects/${projectId}`);
};

const updateProjectById = (projectId, data) => {
  return axios.put(`/projects/${projectId}`, data);
};

const addAnnouncenmentProjects = (data) => {
  return axios.post("/projects", data);
};

const userInviteProjects = (projectId, data) => {
  return axios.post(`/projects/${projectId}/invite`, data);
};

const userAcceptInviteProjects = (data) => {
  return axios.post(`/projects/invitation-accept`, data);
};

const updateProjectVersionById = (projectId, versionId, data) => {
  return axios.put(`/projects/${projectId}/versions/${versionId}`, data);
};

const attachFileProjectVersionById = (data) => {
  return axios.post(`/attachments`, data);
};

const deleteFileProjectVersionById = (attachId) => {
  return axios.delete(`/attachment/${attachId}`);
};

const downloadProjectById = (projectId, data) => {
  return axios.post(`/projects/${projectId}/download`, data, {
    responseType: "blob",
  });
};

const changeStatusProjectById = (projectId, data) => {
  return axios.put(`/projects/${projectId}/change-status`, data);
};

const getProjectTasks = (projectId) => {
  return axios.get(`/projects/${projectId}/tasks`);
};

const addProjectTasks = (projectId, data) => {
  return axios.post(`/projects/${projectId}/tasks`, data);
};

const canceleProyectInvite = (data) => {
  return axios.post(`/projects/cancele-invitation`, data);
};

const attachProjectAval = (data) => {
  return axios.post(`/projects/attachment/aval`, data);
};

export default {
  getProjects,
  getProjectById,
  updateProjectById,
  addAnnouncenmentProjects,
  userInviteProjects,
  updateProjectVersionById,
  attachFileProjectVersionById,
  deleteFileProjectVersionById,
  downloadProjectById,
  changeStatusProjectById,
  getProjectTasks,
  addProjectTasks,
  userAcceptInviteProjects,
  canceleProyectInvite,
  attachProjectAval
};

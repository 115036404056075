<template>
  <div class="">
    <a-link
      :title="$t('Add task')"
      :icon="mdiPlus"
      @click.prevent="onAddTasks"
    />

    <wrapper-drawer v-model="showDrawer" :title="$t('New task')">
      <task-form is-add />
    </wrapper-drawer>
  </div>
</template>

<script>
import { ref, computed } from "@vue/composition-api";
import { mdiPlus } from "@mdi/js";
import ALink from "@/components/general/ALink";
import WrapperDrawer from "@/components/general/WrapperDrawer";
import TaskForm from "./TaskForm";

export default {
  name: "BtnAddTasks",
  components: {
    ALink,
    WrapperDrawer,
    TaskForm,
  },
  setup(props) {
    const showDrawer = ref(false);
    const onAddTasks = () => {
      showDrawer.value = true;
    };

    return {
      showDrawer,
      mdiPlus,
      onAddTasks,
    };
  },
};
</script>

<style></style>

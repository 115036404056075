<template>
  <div class="">
    <div class="" v-if="announcementProjects.length">
      <TitleSection
        uppercase
        big
        :title="`${$t('See tasks')} | ${announcementLang.title}`"
      />
      <v-card class="my-4">
        <v-card-text>
          <project-select
            :projects="announcementProjects"
            return-object
            @input="onSelectProject"
          />
        </v-card-text>
        <v-card-subtitle class="my-4">
          <div class="d-flex flex-row g10" v-if="projectSelected">
            <div class="d-flex align-center">
              <p class="my-0 mr-2">
                <span class="text-uppercase font-weight-bold"
                  >{{
                    `${t(projectSelected.owner.role_name)} ${
                      projectSelected.owner.country_code_name
                    }`
                  }}:</span
                >
              </p>
              <icon-avatar
                color="primary"
                :text="projectSelected.owner.fullname"
              />
            </div>
            <div class="d-flex align-center">
              <div
                class="d-flex align-center"
                v-for="(manager, index) in projectSelected.managers"
                :key="index"
              >
                <p class="my-0 mr-2">
                  <span class="text-uppercase font-weight-bold"
                    >{{
                      `${t(manager.role_name)} ${
                        manager.country_code_name
                      }`
                    }}:</span
                  >
                </p>
                <icon-avatar
                  color="primary"
                  :text="manager.fullname"
                />
              </div>
            </div>
          </div>
        </v-card-subtitle>
      </v-card>
      <areas-manager v-if="projectSelected" />

      <loading :value="loading" />
    </div>

    <v-alert
      v-if="!loading && !announcementProjects.length"
      border="top"
      colored-border
      type="info"
      elevation="2"
    >
      {{ $t("Empty information") }}
    </v-alert>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "@vue/composition-api";
import IconAvatar from "@/components/icons/IconAvatar";
import ProjectSelect from "@/components/projects/ProjectSelect";
import AreasManager from "@/components/areas/AreasManager";
import Loading from "@/components/general/Loading";
import TitleSection from "@/components/general/TitleSection";

import { useAnnouncement } from "@/composables/announcement/useAnnouncement.js";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import router from "@/router";

export default {
  components: {
    IconAvatar,
    AreasManager,
    ProjectSelect,
    Loading,
    TitleSection,
  },
  name: "TasksView",
  setup(props, context) {
    const { t } = useUtilsI18n();
    const {
      announcement,
      announcementLang,
      announcementProjects,
      loading,
      getAnnouncementById,
    } = useAnnouncement(props, context);
    const projectSelected = ref(null);

    const onSelectProject = (project) => {
      projectSelected.value = project;
    };

    onMounted(() => {
      const { id } = router.currentRoute.params;
      if (id)
        getAnnouncementById(id, {
          params: {
            projects: true,
            project_status: "approved",
          },
        });
    });

    return {
      announcement,
      announcementLang,
      announcementProjects,
      loading,
      projectSelected,

      t,
      onSelectProject,
    };
  },
};
</script>

<style></style>

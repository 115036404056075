<template>
  <v-textarea
    :value="value"
    auto-grow
    outlined
    row-height="15"
    hide-details="auto"
    :rows="rows"
    @input="onChangeInput"
    v-bind="$attrs"
    v-on="$listeners"
  ></v-textarea>
</template>

<script>
import { ref } from "@vue/composition-api";

export default {
  props: {
    value: {
      default: null,
    },
    errorMessages: {
      type: String,
      default: null,
    },
    rows: {
      default: 15,
      type: Number,
    },
  },
  setup({ value }, { emit }) {
    const getValue = ref(value);
    const onChangeInput = (value) => {
      emit("onChangeInput", getValue.value);
      emit("input", value);
    };

    return {
      getValue,
      onChangeInput,
    };
  },
};
</script>

<style></style>

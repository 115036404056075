<template>
  <v-select
    :value="value"
    outlined
    dense
    hide-details="auto"
    v-bind="$attrs"
    v-on="$listeners"
    @change="onChangeSelect"
  ></v-select>
</template>

<script>
export default {
  props: {
    value: {
      default: null,
    },
  },
  setup(props, { emit }) {
    const onChangeSelect = (value) => {
      emit("onChangeSelect", value);
      emit("input", value);
    };

    return {
      onChangeSelect,
    };
  },
};
</script>

<style></style>

<template>
  <wrapper-table
    v-if="getProjectSelect && getAreaManagerSelect"
    :title="`${$t('Area')} ${$t(getAreaManagerSelect.name)}`"
  >
    <template #subtitle>
      <btn-add-tasks />
    </template>

    <v-data-table
      :headers="headers"
      :items="tasks"
      :items-per-page="5"
      show-expand
    >
      <template #[`item.task`]="{ item }">
        <div class="d-flex align-center">
          <div class="d-flex ms-3">
            <span
              class="d-block font-weight-semibold text--primary text-truncate mr-4"
              >{{ item.title }}</span
            >
            <icon-message class="mr-2" v-show="false" />
          </div>
        </div>
      </template>

      <template #[`item.status`]="{ item }">
        <task-status :status="item.status" />
      </template>

      <!-- expandad data -->
      <template #expanded-item="{ headers }">
        <td
          :colspan="headers.length"
          class="text-uppercase font-weight-semibold"
        >
          <v-row class="py-4">
            <v-col cols="12" xs="3" sm="3">
              <!-- <p class="my-0">{{ t("Director") }}:</p> -->
            </v-col>
            <v-col cols="12" xs="8" sm="8">
              <!-- <icon-avatar
              image="https://cdn.vuetifyjs.com/images/john.jpg"
              class="mx-1"
            />
            <icon-avatar :text="item.task" :color="item.color" class="mx-1" /> -->
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>
  </wrapper-table>
</template>

<script>
import { computed, watch } from "@vue/composition-api";

// import IconAvatar from "@/components/icons/IconAvatar";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import WrapperTable from "@/components/general/WrapperTable";
import IconAlert from "@/components/icons/IconAlert";
import IconMessage from "@/components/icons/IconMessage";
import IconAccountAlert from "@/components/icons/IconAccountAlert";
import BtnAddTasks from "@/components/tasks/BtnAddTasks";
import TaskStatus from '@/components/tasks/TaskStatus';
import store from "@/store";

export default {
  name: "AreasTaskList",
  components: {
    // IconAvatar,
    IconAlert,
    IconMessage,
    IconAccountAlert,
    WrapperTable,
    BtnAddTasks,
    TaskStatus,
  },
  setup(props) {
    const { t } = useUtilsI18n();
    const headers = computed(() => {
      return [
        { text: t("Tasks"), value: "task" },
        { text: t("Status"), value: "status" },
      ];
    });

    const getProjectSelect = computed(
      () => store.getters["projects/getProjectSelect"]
    );

    const getAreaManagerSelect = computed(
      () => store.getters["areas/getAreaManagerSelect"]
    );

    const tasks = computed(() => getAreaManagerSelect.value?.tasks || []);

    watch(
      () => store.state.projects.projectSelect,
      () => {
        store.commit("areas/SET_AREA_MANAGER_SELECT", null);
      },
      {
        deep: true,
      }
    );

    return {
      tasks,
      headers,
      getProjectSelect,
      getAreaManagerSelect,

      t,
    };
  },
};
</script>

<style></style>

<template>
  <v-overlay v-bind="$attrs">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: "Loading"
}
</script>
import axios from '@axios';

const getAnnouncenments = (options = {}) => {
  return axios.get('/announcenments', options)
};

const getAnnouncementById = (announcementId, options = {}) => {
  return axios.get(`/announcenments/${announcementId}`, options)
};

const addAnnouncements = (data) => {
  return axios.post(`/announcenments`, data)
};

const updateAnnouncementById = (announcementId, data) => {
  return axios.put(`/announcenments/${announcementId}`, data)
};


export default {
  addAnnouncements,
  getAnnouncenments,
  getAnnouncementById,
  updateAnnouncementById
};
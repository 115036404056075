<template>
  <v-select
    :value="value"
    :items="users"
    attach
    chips
    :item-value="(item) => item.id"
    :item-text="(item) => item.full_name"
    v-bind="$attrs"
    v-on="$listeners"
  ></v-select>
</template>

<script>
import { watch, ref, computed, onMounted } from "@vue/composition-api";
import api from "@/services";

export default {
  name: "UserSelect",
  props: {
    value: {
      default: null
    },
    role: {
      type: String | Number,
      default: null
    }
  },

  setup(props) {
    const { role } = props;

    const users = ref([]);

    const getUsers = async () => {
      try {
        const { data: response } = await api.getUsers({
          params: {
            role
          }
        });
        const { data } = response;
        users.value = data;
      } catch (error) {
      } finally {}
    };


    onMounted(() => {
      getUsers();
    });

    return {
      users,
      getUsers
    }
  }
}
</script>

<style>

</style>
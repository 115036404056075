<template>
  <v-form :value="value" v-bind="$attrs" v-on="$listeners" @input="onValidForm">
    <slot />
  </v-form>
</template>

<script>
export default {
  name: "WrapperForm",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {};
  },
  watch: {},
  methods: {
    onValidForm(v) {
      this.$emit("input", v);
    },
  },
};
</script>

<style></style>

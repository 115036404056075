import auth from "./auth";
import general from "./general";
import announcenments from "./announcenments";
import projects from "./projects";
import areas from "./areas";
import templates from "./templates";
import roles from "./roles";
import users from "./users";

export default {
  ...auth,
  ...general,
  ...announcenments,
  ...projects,
  ...areas,
  ...templates,
  ...roles,
  ...users,
};

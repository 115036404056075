import axios from "@axios";

const getTemplates = () => {
  return axios.get("/templates");
};

const addTemplates = (data) => {
  return axios.post("/templates", data);
};

const updateTemplatesById = (templateId, data) => {
  return axios.put(`/templates/${templateId}`, data);
};

const deleteTemplatesById = (templateId) => {
  return axios.delete(`/templates/${templateId}`);
};

const assignTemplatesToProjects = (data) => {
  return axios.post("/templates/project-task-assigns", data);
};

export default {
  getTemplates,
  addTemplates,
  updateTemplatesById,
  deleteTemplatesById,
  assignTemplatesToProjects
};

<template>
  <v-card class="my-4">
    <v-row>
      <v-col cols="12" xs="12" sm="8">
        <v-card-title>{{ title }}</v-card-title>
        <v-card-subtitle>
          <slot name="subtitle"></slot>
        </v-card-subtitle>
      </v-col>
      <v-col cols="12" xs="12" sm="4">
        <slot name="actions-header"></slot>
      </v-col>
    </v-row>
    <v-card-text>
      <slot></slot>
    </v-card-text>
    <v-card-actions>
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "WrapperTable",
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.v-card__actions {
  display: flex;
  justify-content: right;

  button {
    // text-transform: capitalize !important;
  }
}
</style>

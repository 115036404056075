<template>
  <wrapper-table :title="$t('Areas list')" v-if="getProjectSelect">
    <template #subtitle>
      <p>{{ getProjectSelect.project_name }}</p>
    </template>
    <v-data-table
      :headers="headers"
      :items="mergeAreasTasks"
      :items-per-page="5"
      :loading="loadingAreas"
      show-expand
      @click:row="onClickItemArea"
    >
      <template #[`item.areas`]="{ item }">
        <div class="d-flex align-center">
          <icon-avatar :text="item.name" :color="'primary'" />
          <div class="d-flex ms-3">
            <span
              class="d-block font-weight-semibold text--primary text-truncate mr-4"
              >{{ item.name }}</span
            >
            <icon-alert class="mr-2" v-show="false" />
            <icon-message class="mr-2" v-show="false" />
            <icon-account-alert class="mr-2" v-show="false" />
          </div>
        </div>
      </template>

      <template #[`item.tasks`]="{ item }">
        <span>{{ getAreaTasks(item) }}</span>
      </template>

      <template #[`item.progress`]="{ item }">
        <task-progress :tasks="item.tasks" />
      </template>

      <!-- expandad data -->
      <template #expanded-item="{ headers }">
        <td
          :colspan="headers.length"
          class="text-uppercase font-weight-semibold"
        >
          <v-row class="py-4">
            <v-col cols="12" xs="3" sm="3">
              <!-- <p class="my-0">{{ t("Director") }}:</p> -->
            </v-col>
            <v-col cols="12" xs="8" sm="8">
              <!-- <icon-avatar
              image="https://cdn.vuetifyjs.com/images/john.jpg"
              class="mx-1"
            />
            <icon-avatar :text="item.areas" :color="item.color" class="mx-1" /> -->
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>
  </wrapper-table>
</template>

<script>
import { computed, ref, onMounted, watch } from "@vue/composition-api";

import IconAvatar from "@/components/icons/IconAvatar";
import WrapperTable from "@/components/general/WrapperTable";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import IconAlert from "@/components/icons/IconAlert";
import IconMessage from "@/components/icons/IconMessage";
import IconAccountAlert from "@/components/icons/IconAccountAlert";
import TaskProgress from "@/components/tasks/TaskProgress";
import api from "@/services";
import store from "@/store";

export default {
  name: "AreasList",
  components: {
    IconAvatar,
    IconAlert,
    IconMessage,
    IconAccountAlert,
    WrapperTable,
    TaskProgress,
  },
  setup(props) {
    const { t } = useUtilsI18n();
    const areas = ref([]);
    const tasks = ref([]);
    const loadingAreas = ref(false);
    const headers = computed(() => {
      return [
        { text: t("Areas"), value: "areas" },
        { text: t("Tasks"), value: "tasks" },
        { text: t("Progress"), value: "progress" },
      ];
    });

    const getProjectSelect = computed(
      () => store.getters["projects/getProjectSelect"]
    );

    const getSelectProjectTasks = computed(
      () => store.getters["projects/getSelectProjectTasks"]
    );

    const mergeAreasTasks = computed(() => {
      const ts = getSelectProjectTasks.value;
      return [
        ...areas.value.map((area) => {
          const tasks = ts.filter((t) => parseInt(t.area_id) === parseInt(area.id));
          area.tasks = tasks || [];
          return area;
        }),
      ];
    });

    const getAreas = async () => {
      try {
        loadingAreas.value = true;
        const { data: response } = await api.getAreas();
        const { data } = response;
        areas.value = data;

        getProjectTasks();
      } catch (error) {
      } finally {
        loadingAreas.value = false;
      }
    };

    const getProjectTasks = async () => {
      try {
        loadingAreas.value = true;
        const { id: projectId } = getProjectSelect.value;
        const { data: response } = await api.getProjectTasks(projectId);
        const { data } = response;

        store.commit("projects/SET_SELECT_PROJECT_TASKS", data);
      } catch (error) {
      } finally {
        loadingAreas.value = false;
      }
    };

    const getAreaTasks = (area) => {
      return area?.tasks ? area.tasks.length : 0;
    };

    const onClickItemArea = (item) => {
      store.commit("areas/SET_AREA_MANAGER_SELECT", { ...item });
    };

    watch(
      () => store.state.projects.projectSelect,
      () => {
        getProjectTasks();
      },
      {
        deep: true,
      }
    );

    onMounted(() => {
      getAreas();
    });

    return {
      getProjectSelect,
      headers,
      areas,
      loadingAreas,
      mergeAreasTasks,

      getAreaTasks,
      onClickItemArea,
      t,
    };
  },
};
</script>

<style></style>

<template>
  <div class="d-flex aligns-center">
    <v-progress-circular v-bind="progress"></v-progress-circular>
    <span class="px-2">{{ totalPercentage }}%</span>
  </div>
</template>

<script>
import { ref, computed, watch } from "@vue/composition-api";

export default {
  props: {
    tasks: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { tasks } = props;
    const allTasks = ref(tasks);
    const allTaskCompleted = computed(() =>
      allTasks.value.filter((t) => t.status === "completed")
    );
    const totalPercentage = computed(() => {
      const total = allTasks.value.length;
      const completed = allTaskCompleted.value.length;
      return total ? parseInt((completed * 100) / total) : 0;
    });
    const progress = computed(() => {
      const percentage = totalPercentage.value;

      let color = null;
      const progress = {
        value: percentage,
      };

      if (percentage > 0 && percentage <= 49) color = "error";
      if (percentage > 49 && percentage <= 99) color = "warning";
      if (percentage > 99) color = "success";

      return { ...progress, color };
    });

    watch(
      () => props.tasks,
      (tasks) => {
        allTasks.value = tasks;
      },
      {
        deep: true,
        immediate: true,
      }
    );

    return {
      allTasks,
      allTaskCompleted,
      totalPercentage,
      totalPercentage,
      progress,
    };
  },
};
</script>

<style></style>

<template>
  <v-icon :color="color" :size="size" v-bind="$attrs">{{
    mdiAlertOutline
  }}</v-icon>
</template>

<script>
import { mdiAlertOutline } from "@mdi/js";

export default {
  name: "IconAlert",
  props: {
    size: {
      default: "18px",
    },
    color: {
      default: "error",
    },
  },
  setup(props) {
    return {
      mdiAlertOutline,
    };
  },
};
</script>

<style></style>
